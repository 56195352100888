import { useState, createContext, useEffect } from "react";
import "./App.css";
import Toolbar from "./components/shared/Toolbar";
import HomePage from "./pages/HomePage";
import Bio from "./pages/Bio";
import Essays from "./pages/Essays";
import Portfolio from "./pages/Portfolio";
import Katchup from "./pages/Katchup";
import Truphl from "./pages/Truphl";
import Prioreo from "./pages/Prioreo";
import Gravitio from "./pages/Gravitio";
import Bakudu from "./pages/Bakudu";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Connect from "./pages/Connect";

export const pageTransitionContext = createContext();

function App() {
  const [onHome, setOnHome] = useState(true);
  const [pageTransition, setPageTransition] = useState(false);
  const [transitionIn, setTransitionIn] = useState(true);
  const [hideContent, setHideContent] = useState(false);
  const [photo, setPhoto] = useState(false);
  const [fullPageFade, setFullPageFade] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      let toolbar = document.querySelector(".Toolbar");
      if (e.target.scrollTop > 50) {
        toolbar.classList.add("scroll");
      } else {
        toolbar.classList.remove("scroll");
      }
    };

    document.body.addEventListener("scroll", onScroll.bind(this), true);

    return () => {
      window.removeEventListener("scroll", onScroll.bind(this), true);
    };
  }, []);

  return (
    <BrowserRouter>
      <pageTransitionContext.Provider
        value={{
          pageTransition: pageTransition,
          setPageTransition: setPageTransition,
          onHome: onHome,
          setOnHome: setOnHome,
          transitionIn: transitionIn,
          setTransitionIn: setTransitionIn,
          hideContent: hideContent,
          setHideContent: setHideContent,
          photo: photo,
          setPhoto: setPhoto,
          fullPageFade: fullPageFade,
          setFullPageFade: setFullPageFade,
        }}
      >
        <Toolbar />

        <Routes>
          <Route homePage={onHome} exact path="/" element={<HomePage />} />
          <Route homePage={onHome} exact path="/bio" element={<Bio />} />
          <Route
            homePage={onHome}
            exact
            path="/connect"
            element={<Connect />}
          />
          <Route exact path="/essays" element={<Essays />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/portfolio/katchup" element={<Katchup />} />
          <Route exact path="/portfolio/truphl" element={<Truphl />} />
          <Route exact path="/portfolio/prioreo" element={<Prioreo />} />
          <Route exact path="/portfolio/gravitio" element={<Gravitio />} />
          <Route exact path="/portfolio/bakudu" element={<Bakudu />} />
        </Routes>
      </pageTransitionContext.Provider>
    </BrowserRouter>
  );
}

export default App;
