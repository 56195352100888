import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pageTransitionContext } from "../App";
import BackButton from "../components/shared/BackButton";
import "./Portfolio.css";
import KatchupSmall from "./KatchupSmall.png";
import TruphlSmall from "./TruphlSmall.png";
import PrioreoSmall from "./PrioreoSmall.png";
import SimulatorSmall from "./Simulator.png";
import BakuduSmall from "./BakuduSmall.png";

const Portfolio = (props) => {
  const navigation = useNavigate();
  const transitionContext = useContext(pageTransitionContext);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const loadImages = () => {
    setImagesLoaded((prevState) => prevState + 1);
  };

  const navigateToPage = async (page) => {
    await transitionContext.setFullPageFade((prevState) => true);
    await setTimeout(async () => await navigation(`/portfolio/${page}`), 1100);
  };

  useEffect(() => {
    if (imagesLoaded == 5) {
      transitionContext.setFullPageFade(false);
      setImagesLoaded(false);
    }
  }, [imagesLoaded]);

  return (
    <div className="page-content">
      <BackButton />
      <div
        className={`portfolio ${
          transitionContext.fullPageFade ? "fade-in" : ""
        }`}
      >
        <div className="top">
          <div
            className="img katchup"
            onClick={navigateToPage.bind(this, "katchup")}
          >
            <img
              className="katchup"
              src={KatchupSmall}
              onLoad={loadImages}
            ></img>
            <div className="project-title">katchup</div>
          </div>
          <div
            className="img truphl"
            onClick={navigateToPage.bind(this, "truphl")}
          >
            <img src={TruphlSmall} className="truphl" onLoad={loadImages}></img>
            <div className="project-title">truphl</div>
          </div>
        </div>
        <div className="bottom">
          <div
            className="img prioreo"
            onClick={navigateToPage.bind(this, "prioreo")}
          >
            <img
              src={PrioreoSmall}
              className="prioreo"
              onLoad={loadImages}
            ></img>
            <div className="project-title">Prioreo</div>
          </div>
          <div
            className="img gravitio"
            onClick={navigateToPage.bind(this, "gravitio")}
          >
            <img
              src={SimulatorSmall}
              className="gravtio"
              onLoad={loadImages}
            ></img>
            <div className="project-title">gravit.io</div>
          </div>
          <div
            className="img bakudu"
            onClick={navigateToPage.bind(this, "bakudu")}
          >
            <img src={BakuduSmall} className="bakudu" onLoad={loadImages}></img>
            <div className="project-title">Bakudu</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
