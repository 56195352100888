import React, { useContext, useEffect } from "react";
import { pageTransitionContext } from "../App";
import BackButton from "../components/shared/BackButton";
import MainPicture from "../components/shared/MainPicture";
import "./Essays.css";

const Essays = (props) => {
  const transitionContext = useContext(pageTransitionContext);
  useEffect(() => {
    transitionContext.setHideContent(false);
    transitionContext.setPageTransition(false);
    transitionContext.setOnHome(false);
    transitionContext.setTransitionIn(false);
  }, []);

  return (
    <div className="page-content">
      <BackButton />
      <MainPicture />
      <div
        className={`essays ${
          transitionContext.hideContent ? "hide-page-content" : "show-essays"
        }`}
      >
        <div className="header">ESSAYS</div>
        <div className="error-text">Nothing here for now! Check back soon.</div>
      </div>
    </div>
  );
};

export default Essays;
