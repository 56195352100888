import React, { useState, useEffect, useContext, useRef } from "react";
import MainPicture from "../components/shared/MainPicture";
import BackButton from "../components/shared/BackButton";
import { pageTransitionContext } from "../App";
import "./Portfolio.css";
import PrioreoLarge from "./Prioreo.png";
import Close from "../components/shared/Close.png";

const Prioreo = (props) => {
  const transitionContext = useContext(pageTransitionContext);
  const modal = useRef();

  const loadImages = async () => {
    setTimeout(() => {
      transitionContext.setFullPageFade(false);
    }, 1000);
  };

  const openModal = (e) => {
    modal.current.classList.remove("hidden");
  };

  const closeModal = (e) => {
    let clickedClass = e.target.getAttribute("class");
    if (clickedClass != "image-modal") {
      modal.current.classList.add("hidden");
    }
  };

  return (
    <div className={`page-content`}>
      <BackButton />
      <div className="modal hidden" ref={modal} onClick={closeModal}>
        <div className="modal-content">
          <img className="image-modal" src={PrioreoLarge}></img>
          <img className="close" src={Close}></img>
          <div className="modal-image-message">
            Turn sideways for Full Screen
          </div>
        </div>
      </div>
      <div
        className={`page-content ${
          transitionContext.fullPageFade ? "fade-in" : "fade-in-show"
        }`}
      >
        <img
          className={`portfolio-image prioreo`}
          src={PrioreoLarge}
          onLoad={loadImages}
          onClick={openModal}
        ></img>
        <div className={`prioreo-content`}>
          <div className="prioreo-header">Prioreo</div>

          <div className="paragraph">
            Understood in the right light, every “Checklist” that you have ever
            created, or “How To” that you have ever read is a form of goal
            setting. When the path is known, it is easy to attain the goal. But
            when the process is vague, and you’re not certain what comes next,
            or what is the most important thing to focus on, it can lead to
            paralysis. Prioreo thinks for you, so that you can spend your time
            doing.
          </div>
          <div className="paragraph">
            Every user has a dashboard, where they write out their goals and the
            timeline in which they want to accomplish that goal. Each goal
            receives a time block (a period that you are committed to working on
            that goal) and within that goal, they can set tasks for themselves.
            Prioreo automatically takes the tasks, and organizes them into a
            schedule so that you never have to wonder what you should be working
            on.
          </div>
          <div className="paragraph">
            Users can also crowds source to-do lists from the ecosystem,
            seamlessly adding the goal to their list without ever having to
            think through the steps required. “Plan a Wedding”? Someone’s done
            it before. “Lose 15 Pounds”? Someone’s done it before. “Move to
            NYC”? Someone’s done it before. By taking the list that they have
            created, users can simply plug in a date that they want to
            accomplish the task by, set a time-block, and they are off to the
            races.
          </div>
          <div className="built-with">
            Built with React, Express.js, MongoDB, and Node.js
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prioreo;
