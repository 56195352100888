import React, { useState, useEffect, useContext, useRef } from "react";
import MainPicture from "../components/shared/MainPicture";
import BackButton from "../components/shared/BackButton";
import { pageTransitionContext } from "../App";
import "./Portfolio.css";
import BakuduLarge from "./Bakudu.png";
import Close from "../components/shared/Close.png";

const Bakudu = (props) => {
  const transitionContext = useContext(pageTransitionContext);
  const modal = useRef();

  const loadImages = async () => {
    setTimeout(() => {
      transitionContext.setFullPageFade(false);
    }, 1000);
  };

  const openModal = (e) => {
    modal.current.classList.remove("hidden");
  };

  const closeModal = (e) => {
    let clickedClass = e.target.getAttribute("class");
    if (clickedClass != "image-modal") {
      modal.current.classList.add("hidden");
    }
  };

  return (
    <div className={`page-content`}>
      <BackButton />
      <div className="modal hidden" ref={modal} onClick={closeModal}>
        <div className="modal-content">
          <img className="image-modal" src={BakuduLarge}></img>
          <img className="close" src={Close}></img>
          <div className="modal-image-message">
            Turn sideways for Full Screen
          </div>
        </div>{" "}
      </div>
      <div
        className={`page-content ${
          transitionContext.fullPageFade ? "fade-in" : "fade-in-show"
        }`}
      >
        <img
          className={`portfolio-image bakudu`}
          src={BakuduLarge}
          onLoad={loadImages}
          onClick={openModal}
        ></img>
        <div className={`bakudu-content`}>
          <div className="bakudu-header">Bakudu</div>
          <div className="paragraph">
            How well do you know your famous figures? Do know whether Abraham
            Lincoln was born before Justin Bieber? Easy? How about Abraham
            Lincoln and Charles Dickens?
          </div>
          <div className="paragraph">
            Bakudu is an online game where players take turns setting a list of
            historical figures in order by the date of their birth. If you guess
            the order of the cards correctly, you continue on, but if someone
            else guesses the correct location, you lose. At each successful
            guess, another figure is added to the set. The game is played Battle
            Royale style until only one person remains.
          </div>
          <div className="paragraph">
            The “Killer Feature” for this application is built in video
            recording functionality. Users who wish to post their streams to
            YouTube or Twitch need only turn on their camera, and they are ready
            to make content.
          </div>
          <div className="built-with">
            Built with React, Express.js, MongoDB, and Node.js
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bakudu;
