import React, { useEffect, useContext, useState } from "react";
import { pageTransitionContext } from "../../App";
import "./MainPicture.css";
import PhotoTop from "./Photo Top.png";
import PhotoBottom from "./Photo Bottom.png";
import Main from "./Main.png";
import PhotoTop2 from "./Group2T.png";
import PhotoBottom2 from "./Group2B.png";
import Main2 from "./Group2M.png";

const MainPicture = (props) => {
  const transitionContext = useContext(pageTransitionContext);

  useEffect(() => {}, []);

  const photoPlay = (e) => {
    let picture = document.querySelector(".MainPicture");
    picture.classList.add("no-skew");

    e.target.onmousemove = (e) => {
      let mouseX = e.clientX;
      let mouseY = e.clientY;

      let left = picture.getBoundingClientRect().left;
      let right = picture.getBoundingClientRect().right;
      let bottom = picture.getBoundingClientRect().bottom;
      let top = picture.getBoundingClientRect().top;

      let xPercentage = (mouseX - left) / (right - left);
      let yPercentage = (mouseY - top) / (bottom - top);

      let meanX = 2 * (xPercentage - 0.5);
      let meanY = 2 * (yPercentage - 0.5);

      let skewX = meanX * -20;
      let skewY = meanY * -20;

      let distance = Math.sqrt((0 - meanY) ** 2 + (0 - meanX) ** 2);
      let contrastBoost = 1.3 - distance * 0.7;

      picture.style.transform = `rotateX(${skewX}deg) rotateY(${skewY}deg)`;

      picture.style.filter = `contrast(${contrastBoost})`;
    };
  };

  const noSkew = (e) => {
    let picture = document.querySelector(".MainPicture");

    picture.style.transform = `rotateX(${0}deg) rotateY(${0}deg)`;
    picture.style.filter = `contrast(${1})`;

    picture.classList.add("no-skew");
  };

  const changePhotoState = () => {
    transitionContext.setPhoto((prevState) => !prevState);
  };

  const showLogo = (
    <div
      className={`logo ${transitionContext.transitionIn ? "show-logo" : ""}${
        transitionContext.pageTransition ? " hide-logo" : ""
      } ${!transitionContext.onHome ? " hide-logo-end-state" : ""} `}
    >
      <div className="name">jacob sykes</div>
      <div className="divider">|</div>
      <div className="ux">UX</div>
    </div>
  );

  return (
    <div
      className={`MainPicture 
            ${
              transitionContext.onHome &&
              transitionContext.fullPageFade &&
              transitionContext.transitionIn
                ? "fade-in"
                : " "
            } 
      ${
        transitionContext.onHome &&
        transitionContext.fullPageFade &&
        transitionContext.pageTransition
          ? "hide-page-content"
          : " "
      }
`}
      onClick={changePhotoState}
      onMouseOver={photoPlay}
      onMouseOut={noSkew}
    >
      <div className="image-container">
        <div className="picture-top">
          <img src={transitionContext.photo ? PhotoTop : PhotoTop2}></img>
        </div>
        <div
          className={`${
            !transitionContext.onHome ? "full-end-state-" : ""
          }picture-mid`}
        >
          <img
            className={`${transitionContext.transitionIn ? "not-full" : ""} ${
              !transitionContext.onHome ? "full-end-state" : ""
            } ${transitionContext.pageTransition && " full"}`}
            src={transitionContext.photo ? Main : Main2}
          ></img>
          {transitionContext.onHome ? showLogo : ""}
        </div>
        <div className="picture-bottom">
          <img src={transitionContext.photo ? PhotoBottom : PhotoBottom2}></img>
        </div>
      </div>
      <div className="click-me">CLICK TO WATCH ME TRANSFORM</div>
    </div>
  );
};
export default MainPicture;
