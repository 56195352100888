import React, { useState, useEffect, useContext, useRef } from "react";
import MainPicture from "../components/shared/MainPicture";
import BackButton from "../components/shared/BackButton";
import { pageTransitionContext } from "../App";
import "./Portfolio.css";
import KatchupLarge from "./Katchup.png";
import Close from "../components/shared/Close.png";

const Katchup = (props) => {
  const transitionContext = useContext(pageTransitionContext);
  const modal = useRef();

  const loadImages = async () => {
    if (transitionContext.fullPageFade) {
      setTimeout(() => {
        transitionContext.setFullPageFade(false);
      }, 1000);
    }
  };

  const openModal = (e) => {
    modal.current.classList.remove("hidden");
  };

  const closeModal = (e) => {
    let clickedClass = e.target.getAttribute("class");
    if (clickedClass != "image-modal") {
      modal.current.classList.add("hidden");
    }
  };

  return (
    <div className={`page-content`}>
      <BackButton />
      <div className="modal hidden" ref={modal} onClick={closeModal}>
        <div className="modal-content">
          <img className="image-modal" src={KatchupLarge}></img>
          <img className="close" src={Close}></img>
          <div className="modal-image-message">
            Turn sideways for Full Screen
          </div>
        </div>
      </div>
      <div
        className={`page-content ${
          transitionContext.fullPageFade ? "fade-in" : "fade-in-show"
        }`}
      >
        <img
          className={`portfolio-image`}
          src={KatchupLarge}
          onLoad={loadImages}
          onClick={openModal}
        ></img>
        <div className={`katchup-content`}>
          <div className="katchup-header">katchup</div>
          <div className="paragraph">
            In behavioral economics, an “intention gap” is that small space
            inbetween what you want to do, and what you actually do. There are
            few areas in life where this intention gap is as obvious as when you
            tell a friend “we should catch up sometime.” We are building katchup
            to streamline the process of scheduling meetups with your friends.
          </div>
          <div className="paragraph">
            Katchup allows users to assign friends to categories, such as “Best
            Friend” or “Co-Worker”. Users delegate time blocks that are
            available to friends in each of their personal groups, and then
            katchup automatically syncs with personal calendars to determine
            when they
            <i> are </i>
            available, and when they want that user to <i>know</i> that they are
            available.
          </div>
          <div className="paragraph">
            Katchup also makes it possible to easily share photos from your
            “katchup”, so that everyone gets the best possible pictures.
          </div>
          <div className="built-with">
            Built with React-Native, Express.js, MongoDB, and Node.js
          </div>
        </div>
      </div>
    </div>
  );
};

export default Katchup;
