import React, { useContext, useEffect, useRef } from "react";
import MenuContainer from "../components/MenuContainer";
import MainPicture from "../components/shared/MainPicture";
import BackButton from "../components/shared/BackButton";
import { pageTransitionContext } from "../App";

const HomePage = (props) => {
  const transitionContext = useContext(pageTransitionContext);
  const renderCount = useRef(0);

  useEffect(() => {
    transitionContext.setPageTransition(false);
    transitionContext.setTransitionIn(true);
    transitionContext.setOnHome(true);
    if (transitionContext.fullPageFade) {
      setTimeout(async () => {
        await transitionContext.setFullPageFade(false);
      }, 1000);
    }
  }, []);

  return (
    <div className={`page-content`}>
      <BackButton />
      <MenuContainer />
      <MainPicture renderCount={renderCount} />
      <div
        className={`secondary-content ${
          transitionContext.pageTransition ? "show" : " "
        }`}
      ></div>
    </div>
  );
};

export default HomePage;
