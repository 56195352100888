import React, { useState, useEffect, useContext, useRef } from "react";
import MainPicture from "../components/shared/MainPicture";
import BackButton from "../components/shared/BackButton";
import { pageTransitionContext } from "../App";
import "./Portfolio.css";
import TruphlLarge from "./Truphl.png";
import Close from "../components/shared/Close.png";

const Truphl = (props) => {
  const transitionContext = useContext(pageTransitionContext);
  const modal = useRef();

  const loadImages = async () => {
    setTimeout(() => {
      transitionContext.setFullPageFade(false);
    }, 1000);
  };

  const openModal = (e) => {
    modal.current.classList.remove("hidden");
  };

  const closeModal = (e) => {
    let clickedClass = e.target.getAttribute("class");
    if (clickedClass != "image-modal") {
      modal.current.classList.add("hidden");
    }
  };

  return (
    <div className={`page-content`}>
      <BackButton />
      <div className="modal hidden" ref={modal} onClick={closeModal}>
        <div className="modal-content">
          <img className="image-modal" src={TruphlLarge}></img>
          <img className="close" src={Close}></img>
          <div className="modal-image-message">
            Turn sideways for Full Screen
          </div>
        </div>
      </div>
      <div
        className={`page-content ${
          transitionContext.fullPageFade ? "fade-in" : "fade-in-show"
        }`}
      >
        <img
          className={`portfolio-image truphl`}
          src={TruphlLarge}
          onLoad={loadImages}
          onClick={openModal}
        ></img>
        <div className={`truphl-content`}>
          <div className="truphl-header">truphl</div>
          <div className="paragraph">
            Subject Matter Expertise is a quality we admire in those that we
            work alongside of—but in ways that we don’t often acknowledge, it
            can also be an organizations biggest barrier to growth. SMEs house
            inordinate amounts of narrowly focused information inside of their
            head, and when they aren’t available to address questions (or they
            aren’t approachable altogether), projects stall, or fall apart
            completely, and opportunities to scale successful efforts become
            constrained by the groups over-reliance on one person.
          </div>
          <div className="paragraph">
            So how does Truphl resolve this? Whether it’s Figma, Adobe,
            SalesForce, or any other SAAS imaginable, every organization has
            their own recipe for using the tools available to them. Truphl
            allows SME’s to easily screen capture training material using the
            Truphl browser extension, and publish it privately to users within
            their organization, or publicly to anyone who is trying to become
            more effective. Truphl then attaches the video to the elements in
            the browser that the user interacted with. Anyone can easily hover
            over the element, page, or website that they want to learn more
            about, press Command + Shift, and access a context menu of common
            questions, or pose a questin themselves.
          </div>
          <div className="paragraph">
            Within the Truphl application itself, the value proliferates, as the
            comment section is replaced with the “Question” section, where each
            question likewise redirects to another Truphl. This can help
            developers identify customer confusion, it helps the SME avoid
            repeating the same material, and it helps create fractal content,
            where each piece of new material serves as a launching pad for more
            new material.
          </div>
          <div className="built-with">
            Built with React, Express.js, MongoDB, and Node.js
          </div>
        </div>
      </div>
    </div>
  );
};

export default Truphl;
