import React, { useEffect, useContext } from "react";
import MainPicture from "../components/shared/MainPicture";
import BackButton from "../components/shared/BackButton";
import "./Connect.css";
import Instagram from "./instagram.png";
import LinkedIn from "./LinkedIn.png";
import Facebook from "./facebook.png";
import Twitter from "./twitter.png";
import Mail from "./Mail.png";
import { pageTransitionContext } from "../App";

const Connect = (props) => {
  const transitionContext = useContext(pageTransitionContext);

  useEffect(() => {
    transitionContext.setHideContent(false);
    transitionContext.setPageTransition(false);
    transitionContext.setOnHome(false);
    transitionContext.setTransitionIn(false);
  }, []);

  const goToInstagram = () => {
    window.open("https://www.instagram.com/jmsykes94");
  };
  const goToLinkedIn = () => {
    window.open("https://www.linkedin.com/in/jacob-sykes-a5784971");
  };
  const goToFacebook = () => {
    window.open("https://www.facebook.com/jake.sykes.12");
  };
  const goToTwitter = () => {
    window.open("https://www.twitter.com/jacobmsykes");
  };
  const samAltman = () => {
    window.open("https://blog.samaltman.com/advice-for-ambitious-19-year-olds");
  };

  return (
    <div className="page-content">
      <BackButton />
      <MainPicture />
      <div
        className={`connect ${
          transitionContext.hideContent ? "hide-page-content" : "show-connect"
        }`}
      >
        <div className="header">CONNECT</div>
        <div className="quote link">
          <div onClick={samAltman} className="quote-text">
            "No matter what you choose, build stuff, and be around smart
            people."
          </div>
          <div className="subject">SAM ALTMAN</div>
        </div>
        <div className="socials">
          <div onClick={goToInstagram} className="button">
            <img className="instagram" src={Instagram}></img>
          </div>
          <div onClick={goToLinkedIn} className="button">
            <img className="linkedin" src={LinkedIn}></img>
          </div>
          <div onClick={goToFacebook} className="button">
            <img className="facebook" src={Facebook}></img>
          </div>
          <div onClick={goToTwitter} className="button">
            <img className="twitter" src={Twitter}></img>
          </div>
          <a href="mailto: nyc.jmsykes@gmail.com" className="button">
            <img className="mail" src={Mail}></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Connect;
