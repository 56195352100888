import React, { useContext, useRef } from "react";
import "./Toolbar.css";
import { useNavigate } from "react-router-dom";
import { pageTransitionContext } from "../../App";
import BackButton from "./BackButton";
import BackChevron from "./BackChevron.png";

const Toolbar = (props) => {
  const navigation = useNavigate();
  const transitionContext = useContext(pageTransitionContext);

  const goHome = async () => {
    await window.scrollTo({ top: 0, behavior: "smooth" });
    await transitionContext.setHideContent(true);
    await setTimeout(async () => {
      await navigation("/");
    }, 1200);
  };

  const goBack = async () => {
    if (
      String(window.location).includes(
        "katchup" || "truphl" || "prioreo" || "gravitio" || "bakudu"
      )
    ) {
      await transitionContext.setFullPageFade(true);
      await setTimeout(async () => {
        await navigation.navigate("/portfolio");
      }, 1200);
    }
    if (String(window.location).includes("/portfolio")) {
      await transitionContext.setFullPageFade(true);
    }
    await transitionContext.setHideContent(true);
    await setTimeout(async () => {
      await navigation(-1);
    }, 1200);
  };

  return (
    <div className={`Toolbar`}>
      <img className="toolbar-back" src={BackChevron} onClick={goBack}></img>
      <div className="toolbar-center">
        <div onClick={goHome} className="name">
          jacob sykes
        </div>
      </div>
      <div className="toolbar-right"></div>
    </div>
  );
};

export default Toolbar;
