import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./BackButton.css";
import BackChevron from "./BackChevron.png";
import { pageTransitionContext } from "../../App";

const BackButton = (props) => {
  const navigation = useNavigate();
  const transitionContext = useContext(pageTransitionContext);

  useEffect(() => {}, []);

  const goBack = async () => {
    if (String(window.location).includes("/portfolio")) {
      await transitionContext.setFullPageFade(true);
    }
    await transitionContext.setHideContent(true);
    await setTimeout(async () => {
      await navigation(-1);
    }, 1200);
  };

  return (
    <div className="BackButton">
      <img onClick={goBack} className="icon" src={BackChevron}></img>
    </div>
  );
};

export default BackButton;
