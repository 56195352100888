import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import MenuContainer from "../components/MenuContainer";
import MainPicture from "../components/shared/MainPicture";
import BackButton from "../components/shared/BackButton";
import "./Bio.css";
import { pageTransitionContext } from "../App";

const Bio = (props) => {
  const [pageInactive, setPageInactive] = useState(true);
  const transitionContext = useContext(pageTransitionContext);

  const deactivatePage = (input) => {
    setPageInactive(input);
  };

  useEffect(() => {
    transitionContext.setPageTransition(false);
    transitionContext.setOnHome(false);
    transitionContext.setHideContent(false);
    transitionContext.setTransitionIn(false);
  }, []);

  return (
    <div className={`page-content`}>
      <BackButton />
      <MainPicture />
      <div
        className={`bio ${
          transitionContext.hideContent ? "hide-page-content" : "show-bio"
        }`}
      >
        <div className="header">BIO</div>
        <div className="paragraph">
          Jacob Sykes is a technology consultant and data scientist based out of
          New York City. He specializes in SAAS, UX Design, Consumer Psychology,
          Behavioral Economics, Strategy, Automation, and Machine Learning.
        </div>
        <div className="paragraph">
          While at Tyler Technologies, he designed and oversaw the development
          of bulk inventory management software, and later, served as council to
          executive leadership, where he produced deep-learning models to guide
          expectations and decision making.
        </div>
        <div className="paragraph">
          He has extensive working experience with React.js, React-Native,
          JavaScript, Node, Express, MongoDB, MySQL, Python, TensorFlow, Git,
          Azure, Jira, AWS, and much more.
        </div>
        <div className="paragraph">
          When he isn't building software, he is usually curled up with
          textbooks (his bookshelf is always on loan), hanging out with fellow
          tech nerds, or watching Jeopardy with his wife.
        </div>
        <div className="paragraph">
          Jacob is a graduate of Belmont University, where he studied Political
          Science and Public Policy, with a focus on Industrial Urban Economics.
        </div>
        <Link
          to="/robots.txt"
          download
          target="_blank"
          className="download-resume"
        >
          DOWNLOAD RESUME
        </Link>
      </div>
    </div>
  );
};

export default Bio;
