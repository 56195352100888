import React, { useState, useContext, useEffect } from "react";
import { pageTransitionContext } from "../App";
import "./MenuContainer.css";
import { Link, useNavigate } from "react-router-dom";

const MenuContainer = (props) => {
  const transitionContext = useContext(pageTransitionContext);

  const navigation = useNavigate();

  useEffect(() => {}, []);

  const deactivatePage = async (page) => {
    await transitionContext.setTransitionIn(false);
    await transitionContext.setPageTransition(async (prevState) => await true);
    await setTimeout(async () => {
      await window.scrollTo({ top: 0, behavior: "smooth" });
      await transitionContext.setPageTransition(false);
      await transitionContext.setOnHome(false);
      await transitionContext.setHideContent(false);
      await navigation(page);
    }, 2600);
  };

  const hideContent = async (page) => {
    await transitionContext.setTransitionIn(false);
    await transitionContext.setPageTransition(true);
    await transitionContext.setFullPageFade(true);
    await setTimeout(async () => {
      await window.scrollTo({ top: 0, behavior: "smooth" });
      await navigation(page);
    }, 1000);
  };

  return (
    <div
      className={`MenuContainer ${
        transitionContext.fullPageFade && !transitionContext.transitionIn
          ? " hide-page-content"
          : " "
      }
      ${
        transitionContext.fullPageFade && transitionContext.transitionIn
          ? " fade-in"
          : " "
      }
      ${
        transitionContext.transitionIn && !transitionContext.fullPageFade
          ? "active"
          : " "
      }
      ${transitionContext.pageTransition ? " inactive" : ""}`}
    >
      <div className="container">
        <div onClick={deactivatePage.bind(this, "/bio")} className="item">
          BIO
        </div>
        <div onClick={hideContent.bind(this, "/portfolio")} className="item">
          PORTFOLIO
        </div>
        <div onClick={deactivatePage.bind(this, "/connect")} className="item">
          CONNECT
        </div>
        <div onClick={deactivatePage.bind(this, "/essays")} className="item">
          ESSAYS
        </div>
      </div>
    </div>
  );
};

export default MenuContainer;
