import React, { useState, useEffect, useContext, useRef } from "react";
import MainPicture from "../components/shared/MainPicture";
import BackButton from "../components/shared/BackButton";
import { pageTransitionContext } from "../App";
import "./Portfolio.css";
import GravitioLarge from "./Simulator.png";
import Close from "../components/shared/Close.png";

const Gravitio = (props) => {
  const transitionContext = useContext(pageTransitionContext);
  const modal = useRef();

  const loadImages = async () => {
    setTimeout(() => {
      transitionContext.setFullPageFade(false);
    }, 1000);
  };

  const openModal = (e) => {
    modal.current.classList.remove("hidden");
  };

  const closeModal = (e) => {
    let clickedClass = e.target.getAttribute("class");
    if (clickedClass != "image-modal") {
      modal.current.classList.add("hidden");
    }
  };

  return (
    <div className={`page-content`}>
      <BackButton />
      <div className="modal hidden" ref={modal} onClick={closeModal}>
        <div className="modal-content">
          <img className="image-modal" src={GravitioLarge}></img>
          <img className="close" src={Close}></img>
          <div className="modal-image-message">
            Turn sideways for Full Screen
          </div>
        </div>{" "}
      </div>
      <div
        className={`page-content ${
          transitionContext.fullPageFade ? "fade-in" : "fade-in-show"
        }`}
      >
        <img
          className={`portfolio-image truphl`}
          src={GravitioLarge}
          onLoad={loadImages}
          onClick={openModal}
        ></img>
        <div className={`gravitio-content`}>
          <div className="gravitio-header">gravit.io</div>
          <div className="paragraph">
            The simplest questions in life often have the most complicated
            answers. One such question is “what happens when I drop something?”
            Gravit.io was born out of my lifelong fascination with the elegance
            of astrophysics, mathematics, and cosmology.
          </div>
          <div className="paragraph">
            Gravit.io uses software to simulate the motion of planets, stars,
            dust, and protoplanetary disks—calculating the effects of gravity on
            orbital velocity so that users can visualize what happens when
            bodies interact in space, and what happens when they collide.
          </div>
          <div className="built-with">
            Built with React, Express.js, MongoDB, and Node.js
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gravitio;
